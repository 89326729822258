import firebase from 'firebase/app'
import 'firebase/firebase-analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { customLog } from '@/globals/javascript/custom-log'

// Your web app's Firebase configuration
// - Dev
const firebaseConfigDev = {
  apiKey: 'AIzaSyBuV1SClPK2-XpsFrODHp2ZesEwVCK9zxk',
  authDomain: 'milva-e-dev.firebaseapp.com',
  projectId: 'milva-e-dev',
  storageBucket: 'milva-e-dev.appspot.com',
  messagingSenderId: '587931696967',
  appId: '1:587931696967:web:bc8d2b31246be8f859d662',
  measurementId: 'G-8V0CM0FNHE',
}

// - Prod
const firebaseConfigProd = {
  apiKey: 'AIzaSyDovoQLk7trak1rPfQvmHJfIVgdZxkHnUc',
  authDomain: 'milva-e-prod.firebaseapp.com',
  projectId: 'milva-e-prod',
  storageBucket: 'milva-e-prod.appspot.com',
  messagingSenderId: '587293013468',
  appId: '1:587293013468:web:2f183a7c0d90551b678b19',
  measurementId: 'G-4V3XSEJCH4',
}

// Init
const firebaseApp = firebase.initializeApp(process.env.NODE_ENV === 'development' ? firebaseConfigDev : firebaseConfigProd)
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()

// Analytics
firebaseApp.analytics()

// Logs
customLog('firebase', 'Project ID:', firebaseApp.options.projectId)

// Export
export {
  firebaseApp,
  firebase,
  db,
  auth,
  storage,
}
