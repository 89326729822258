<template>
  <div class="Header">
    <span class="Title">{{ mixWB($route.meta.titleWordbook) }}</span>

    <div class="RightSide">
      <div class="Avatar">
        <span class="Name">{{ currentUserData.firstName }} {{ currentUserData.lastName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'currentUserData',
    ]),
  },
}
</script>

<style lang="stylus" scoped>
  .Header
    padding 20px 0px
    border-bottom 1px solid $color_grey_light
    margin-bottom 20px
    display flex
    justify-content space-between
    align-items center

  .Title
    font-size 2rem
    text-transform uppercase

  .Avatar
    display flex
    align-items center
</style>
