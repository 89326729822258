export const getElementDocumentPos = (elem) => {
  let offsetTop = 0
  let offsetLeft = 0
  let offsetBottom = 0
  const savedElem = elem

  do {
    if (!Number.isNaN(elem.offsetTop)) offsetTop += elem.offsetTop
    if (!Number.isNaN(elem.offsetLeft)) offsetLeft += elem.offsetLeft
  // eslint-disable-next-line no-cond-assign
  } while ((elem = elem.offsetParent) !== null)

  if (offsetTop) {
    offsetBottom = offsetTop + savedElem.getBoundingClientRect().height
  }

  return { top: offsetTop, left: offsetLeft, bottom: offsetBottom }
}

export const scrollTo = ({
  yPos = null,
  duration = 250,
  element = null,
  offset = 0,
  checkBetween = false,
  goToBottom = false,
}) => {
  const startingY = window.pageYOffset
  const screenEnd = startingY + window.innerHeight
  let endingY = null

  // Use yPos
  if (yPos !== null) endingY = yPos + offset

  // Use element
  if (element) {
    const elementPos = getElementDocumentPos(element)
    endingY = elementPos.top + offset

    if (goToBottom) {
      endingY = endingY + elementPos.bottom - elementPos.top - window.innerHeight
    }
  }

  if (endingY === null) {
    endingY = 0
  }

  if (checkBetween && endingY > startingY && endingY < screenEnd) return

  const diff = endingY - startingY
  // eslint-disable-next-line no-plusplus
  const easing = (t) => --t * t * t + 1 // Easing from: https://gist.github.com/gre/1650294
  let start

  if (!diff) return

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    const time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent)

    window.scrollTo(0, startingY + diff * percent)

    // Proceed with animation as long as we wanted it to.
    if (time < duration) window.requestAnimationFrame(step)
  })
}

export const getBrowserInfo = () => {
  const ua = navigator.userAgent
  let tem
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: (tem[1] || '') }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  // eslint-disable-next-line no-cond-assign
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1])
  }
  return { name: M[0], version: M[1] }
}
