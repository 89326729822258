import { auth, firebase, db } from '@/firebase/init'
import { reportError } from '@/globals/javascript/custom-error-handing'
import router from '@/router'

const DB_EMAIL_MAPPING = 'EmailMapping'
const DB_USERS = 'Users'
const DB_GROUPS = 'Groups'
const DB_STATS = 'Stats'
const DB_ANSWERS = 'Answers'

export const users = {
  state: {
    currentUser: {
      id: null,
      data: null,
      answers: null,
    },

    // Unsubscribers
    unsubscribeCurrentUser: null,
    unsubscribeCurrentUserAnswers: null,
  },
  mutations: {
    setCurrentUser: (state, { unsubscribe, id, data }) => {
      state.unsubscribeCurrentUser = unsubscribe
      state.currentUser.id = id
      state.currentUser.data = data

      // Sentry
      // Sentry.setTag('user-id', id || null)
      // Sentry.setTag('user-first-name', data.firstName || null)
    },
    setCurrentUserAnswers: (state, { unsubscribe, data }) => {
      state.unsubscribeCurrentUserAnswers = unsubscribe
      state.currentUser.answers = data
    },
    resetCurrentUser: (state) => {
      if (state.unsubscribeCurrentUser) {
        state.unsubscribeCurrentUser()
        state.unsubscribeCurrentUser = null

        state.currentUser.id = null
        state.currentUser.data = null
      }

      if (state.unsubscribeCurrentUserAnswers) {
        state.unsubscribeCurrentUserAnswers()
        state.unsubscribeCurrentUserAnswers = null

        state.currentUser.answers = null
      }
    },
  },
  actions: {
    async createUserFromGroup(store, {
      email, groupID,
    }) {
      // Check if user already exists
      const user = await db.collection(DB_EMAIL_MAPPING).doc(email).get()

      if (user.exists) {
        return
      }

      try {
        // Create user
        const docRef = db.collection(DB_USERS).doc()
        const newID = docRef.id

        db.collection(DB_USERS).doc(newID).set({
          groupID,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastLogin: null,
          firstName: '',
          lastName: '',
          workTitle: '',
          totalLogins: 0,
          hasCompletedOnce: false,
          hasCompletedOnceDate: null,
        })

        // Create email mapping
        db.collection(DB_EMAIL_MAPPING).doc(email).set({
          userID: newID,
        })

        // Update group members
        db.collection(DB_GROUPS).doc(groupID).set({
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
          memberUserIDs: firebase.firestore.FieldValue.arrayUnion(newID),
          totalMembers: firebase.firestore.FieldValue.increment(1),
        }, { merge: true })

        // Update overall stats
        db.collection(DB_STATS).doc('--stats--').set({
          totalMembers: firebase.firestore.FieldValue.increment(1),
        }, { merge: true })
      }
      catch (err) {
        reportError({ error: err, place: 'createUserFromGroup' })
      }
    },
    async getCurrentUser({ commit }, email) {
      // Get userID from email
      const user = await db.collection(DB_EMAIL_MAPPING).doc(email).get()

      if (!user.exists) {
        auth.signOut()
        router.push({ name: 'Home' })
        return
      }

      // Get user object
      const userData = user.data()
      const unsubscribe = db
        .collection(DB_USERS)
        .doc(userData.userID)
        .onSnapshot((doc) => {
          commit('setCurrentUser', {
            unsubscribe,
            id: doc.id,
            data: doc.data(),
          })
        })
    },
    getCurrentUserAnswers: ({ commit, getters }) => {
      const waitForLoad = setInterval(() => {
        if (!getters.currentUserID) {
          return
        }

        clearInterval(waitForLoad)

        const unsubscribe = db
          .collection(DB_ANSWERS)
          .doc(getters.currentUserID)
          .onSnapshot((doc) => {
            commit('setCurrentUserAnswers', {
              unsubscribe,
              data: doc.data(),
            })
          })
      }, 100)
    },
    setUserInfo: ({ getters }, { firstName, lastName, workTitle }) => {
      db
        .collection(DB_USERS)
        .doc(getters.currentUserID)
        .set({
          firstName,
          lastName,
          workTitle,
        }, { merge: true })
    },
    setStatsOnLogin: ({ getters }) => {
      const waitForCurrentUser = setInterval(() => {
        if (!getters.currentUserLoaded) {
          return
        }

        clearInterval(waitForCurrentUser)

        // Update overall stats
        db
          .collection(DB_STATS)
          .doc('--stats--')
          .set({
            totalLogins: firebase.firestore.FieldValue.increment(1),
          }, { merge: true })

        // Update stats on the group
        db
          .collection(DB_GROUPS)
          .doc(getters.currentUserData.groupID)
          .set({
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            totalLogins: firebase.firestore.FieldValue.increment(1),
          }, { merge: true })

        // Update stats on the user
        db
          .collection(DB_USERS)
          .doc(getters.currentUser.id)
          .set({
            lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
            totalLogins: firebase.firestore.FieldValue.increment(1),
          }, { merge: true })
      }, 100)
    },
    resetCurrentUser: ({ commit }) => {
      commit('resetCurrentUser')
    },
    async isUserAdmin(store, email) {
      let allGood = true
      const emailUser = await db.collection(DB_EMAIL_MAPPING).doc(email).get()

      if (!emailUser.exists) {
        allGood = false
      }

      if (allGood && emailUser.exists) {
        const emailUserData = emailUser.data()

        // Get user object
        const user = await db
          .collection(DB_USERS)
          .doc(emailUserData.userID)
          .get()

        if (user) {
          const userData = user.data()

          if (!userData.isAdmin) {
            allGood = false
          }
        }
        else {
          allGood = false
        }
      }

      return new Promise((resolve) => {
        resolve(allGood)
      })
    },
    async doesUserExist(store, email) {
      let allGood = true
      const emailUser = await db.collection(DB_EMAIL_MAPPING).doc(email).get()

      if (!emailUser.exists) {
        allGood = false
      }

      return new Promise((resolve) => {
        resolve(allGood)
      })
    },
    setStatsOnCompletedSurvey: ({ getters }) => {
      try {
        // Set completed state on user
        db
          .collection(DB_USERS)
          .doc(getters.currentUserID)
          .set({
            hasCompletedOnce: true,
            hasCompletedOnceDate: firebase.firestore.FieldValue.serverTimestamp(),
          }, { merge: true })

        // Set number of completed users
        db.collection(DB_STATS).doc('--stats--').set({
          usersCompletedOnce: firebase.firestore.FieldValue.arrayUnion(getters.currentUserID),
        }, { merge: true })
      }
      catch (err) {
        reportError({ error: err, place: 'setCompletedOnce' })
      }
    },
  },
  getters: {
    // Loaded getters
    currentUserLoaded: (state) => !!state.unsubscribeCurrentUser,
    currentUserAnswersLoaded: (state) => !!state.unsubscribeCurrentUserAnswers,

    // Other getters
    currentUser: (state) => state.currentUser,
    currentUserID: (state) => state.currentUser.id,
    currentUserData: (state) => state.currentUser.data,
    currentUserAnswers: (state) => state.currentUser.answers,
    currentUserNextStep: (state, getters) => {
      if (!state.currentUser.answers) {
        return {
          schema: getters.questionSchemas[0],
          started: false,
          allDone: false,
        }
      }

      const nextSchema = getters.questionSchemas.find((x) => !state.currentUser.answers[x.id])

      if (nextSchema) {
        return {
          schema: nextSchema,
          started: true,
          allDone: false,
        }
      }

      return {
        schema: getters.questionSchemas[0],
        started: true,
        allDone: true,
      }
    },
  },
}
