const adminChecklist = [
  'currentUserLoaded',
  'allGroupsLoaded',
]

export const adminRoutes = [
  // Admin
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      fullBackground: true,
      isGuestPage: true,
      isAdminLoginPage: true,
    },
    component: () => import('@/views/Admin/Login'),
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    meta: {
      fullBackground: true,
      isGuestPage: true,
      isAdminLoginPage: true,
    },
    component: () => import('@/views/Admin/Login'),
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    meta: {
      titleWordbook: 'DASHBOARD',
      isAdminPage: true,
      checklist: adminChecklist,
    },
    component: () => import('@/views/Admin/Dashboard'),
  },
  {
    path: '/admin/new-group',
    name: 'AdminNewGroup',
    meta: {
      titleWordbook: 'CREATE_NEW_GROUP',
      isAdminPage: true,
      checklist: adminChecklist,
    },
    component: () => import('@/views/Admin/NewGroup'),
  },
  {
    path: '/admin/all-groups',
    name: 'AdminAllGroups',
    meta: {
      titleWordbook: 'ALL_GROUPS',
      isAdminPage: true,
      checklist: adminChecklist,
    },
    component: () => import('@/views/Admin/AllGroups'),
  },
  {
    path: '/admin/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {},
    component: () => import('@/views/Admin/NotFound'),
  },
]
