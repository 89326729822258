const ignoreList = []

export const customLog = (tag, text, ...props) => {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  if (ignoreList.includes(tag)) {
    return
  }

  // eslint-disable-next-line dot-notation
  window['console']['log'](`[${ tag }] ${ text }`, ...props)
}
