// Sentry does not work with Vue 3.
// This is meant to catch all errors and send them to Firestore

import { firebase, db } from '@/firebase/init'
import { getBrowserInfo } from '@/globals/javascript/util'
import store from '@/store/index'

const DB_REPORTS = 'Reports'

export const reportError = ({ error, place = null }) => {
  // Check number of errors sent
  let userErrorCount = window.sessionStorage.getItem('userErrorCount')
  if (!userErrorCount) {
    userErrorCount = 0
  }
  else {
    userErrorCount = Number(userErrorCount)
  }
  userErrorCount += 1
  window.sessionStorage.setItem('userErrorCount', userErrorCount)

  if (userErrorCount > 10) {
    return
  }

  // User Error ID
  let userErrorID = window.localStorage.getItem('userErrorID')
  if (!userErrorID) {
    userErrorID = `error-id-${ new Date().getTime() }`
    window.localStorage.setItem('userErrorID', userErrorID)
  }

  // User info
  const { currentUserID } = store.getters
  const { currentUserData } = store.getters

  // Browser info
  const browserInfo = getBrowserInfo()

  const data = {
    userErrorID,
    place,
    path: window.location.href,
    date: new Date().toUTCString(),
    name: error.name || null,
    message: error.message || null,
    line: error.lineno || null,
    col: error.colno || null,
    file: error.filename || null,
  }

  if (currentUserID) {
    data.user = {
      userID: currentUserID,
      userFirstName: currentUserData.firstName,
    }
  }

  if (browserInfo) {
    data.browser = {
      ...browserInfo,
    }
  }

  const now = new Date()
  const date = now.getDate()
  const month = now.getMonth() + 1
  const year = now.getFullYear()

  db
    .collection(DB_REPORTS)
    .doc('--reports--')
    .set({
      errors: {
        [`${ year }-${ month < 10 ? `0${ month }` : month }-${ date < 10 ? `0${ date }` : date }`]: {
          totalErrors: firebase.firestore.FieldValue.increment(1),
          errors: firebase.firestore.FieldValue.arrayUnion(data),
        },
      },
    }, { merge: true })

  db
    .collection(DB_REPORTS)
    .doc('--reports--')
    .set({
      totalErrors: firebase.firestore.FieldValue.increment(1),
    }, { merge: true })
}
