<template>
  <div class="AdminLayout">
    <Menu />

    <div class="PageWrap">
      <Header />
      <router-view/>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Admin/Menu'
import Header from '@/components/Admin/Header'

export default {
  name: 'AdminLayout',
  components: { Menu, Header },

}
</script>

<style lang="stylus" scoped>
  .AdminLayout
    display flex
    justify-content flex-end
    background-color $color_grey_lightest
    min-height 100vh
    border-bottom 1px solid $color_grey_light

  .PageWrap
    width calc(100% - 330px - 30px)
    margin-right 30px
</style>
