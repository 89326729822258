import questionSchemas from '@/database/question-schemas.json'
import { sortBy } from 'lodash-es'

export const database = {
  state: {
    questionSchemas,
  },
  mutations: {},
  actions: {},
  getters: {
    questionSchemas: (state) => sortBy(state.questionSchemas, ['position']),
  },
}
