import wordbooks from '@/database/wordbooks.json'
import { isArray } from 'lodash-es'
import moment from 'moment-with-locales-es6'

export const mixins = {
  methods: {
    mixWB(key, params = []) {
      const lang = 'DK'
      const wordbook = wordbooks[key] || false

      if (!key) {
        return ''
      }

      if (!wordbook || !wordbook[lang]) {
        return key.replace('W: ', '')
      }

      let textToUse = wordbook[lang]

      params.forEach((param) => {
        textToUse = textToUse.replace('%s', param)
      })

      return textToUse
    },
    mixGetDate(dateObject, formatting = 'lll') {
      if (!dateObject) {
        return ''
      }
      const theDate = moment(dateObject.toDate())
      theDate.locale('da-DK')
      const formattedDate = theDate.format(formatting)
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    },
    mixCompareVersionsNewerOnly(versionA, versionB) {
      if (!versionA || !versionB) {
        return false
      }

      const versionAArray = versionA.split('.')
      const versionBArray = versionB.split('.')

      let isVersionANewer = null
      versionBArray.forEach((num, index) => {
        if (isVersionANewer !== null) {
          return
        }
        if (Number(num) === Number(versionAArray[index])) {
          return
        }
        isVersionANewer = Number(num) < Number(versionAArray[index])
      })

      if (isVersionANewer === null) {
        return false
      }

      return isVersionANewer
    },
    mixGetSessionStorageData({ key, defaultValue }) {
      try {
        const stringValue = window.sessionStorage.getItem(key)
        if (!stringValue) {
          return defaultValue
        }

        if (isArray(defaultValue)) {
          const value = JSON.parse(stringValue)
          return value || []
        }

        return stringValue
      }
      catch (error) {
        return defaultValue
      }
    },
    mixSetSessionStorageData({ key, value, type }) {
      const stringValue = window.sessionStorage.getItem(key)

      if (isArray(type)) {
        const array = JSON.parse(stringValue) || []

        array.push(value)
        window.sessionStorage.setItem(key, JSON.stringify(array))
      }
    },
  },
}

export const { mixWB } = mixins.methods
export const { mixCompareVersionsNewerOnly } = mixins.methods
