<template>
  <SmallScreenOverlay
    :minScreenWidth="minScreenWidth"
    v-if="windowWidth && windowWidth < minScreenWidth" />
  <div v-else>
    <div
      :class="bodyClasses"
      class="Body">
      <!-- Admin - logged in -->
      <AdminLayout v-if="isAdminPage" />

      <router-view
        v-else
        :key="$route.fullPath" />
    </div>

    <Footer />

    <LoadingPage :show="isPageLoading" />

  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import LoadingPage from '@/components/LoadingPage'
import SmallScreenOverlay from '@/components/SmallScreenOverlay'
import Footer from '@/components/Footer'
import { mapGetters } from 'vuex'
import { auth } from '@/firebase/init'

export default {
  name: 'App',
  data() {
    return {
      minScreenWidth: 1400,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapGetters([
      'isAppInitialized',
      'isPageLoading',

      // For testing
      'isLoggedIn',
      'currentUser',
      'currentGroupData',
      'questionSchemas',
      'allStats',
      'allGroups',
      'allGroupsAsObject',
      'currentSchemaComments',
      'packageVersion',
      'firestoreAppVersion',
    ]),
    bodyClasses() {
      return {
        FullBackground: this.$route.meta.fullBackground,
        NormalBackGround: !this.$route.meta.fullBackground,
      }
    },
    isAdminPage() {
      return this.$route.meta.isAdminPage
    },
  },
  watch: {
    $route() {
      this.scrollToTop()
    },
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth
    },
    scrollToTop() {
      requestAnimationFrame(() => {
        window.scrollTo(0, 0)
      })
    },
  },
  components: {
    AdminLayout,
    LoadingPage,
    Footer,
    SmallScreenOverlay,
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize)
    if (window.innerWidth < this.minScreenWidth) {
      this.$store.dispatch('reportWindowSize')
    }

    auth.onAuthStateChanged((user) => {
      // Set app as initialized
      if (!this.isAppInitialized) {
        this.$store.dispatch('setAppInitializedState', true)
      }

      // Update user login state
      this.$store.dispatch('setUserLoginState', !!user)

      if (user) {
        this.$store.dispatch('getCurrentUser', user.email)
        // auth.signOut()
      }
      else {
        this.$store.dispatch('setUserLoginState', false)
        requestAnimationFrame(() => {
          this.$store.dispatch('resetOnLogout')
        })
      }
    })
  },
}
</script>

<style lang="stylus">
  .NormalBackGround
    width 100%
    min-height 100vh
    background-color $color_grey_lightestest

  .FullBackground
    width 100%
    min-height 100vh
    background-color #000
    background-image url('/img/bg-1.jpg')
    background-size cover
</style>
