<template>
  <div class="SmallScreenOverlay">
    <div class="Body">
      <SimpleHeader />

      <div class="ContentWrap">
        <span class="Title">{{ mixWB('BIGGER_SCREEN_REQUIRED') }}</span>
        <div class="IconWrap">
          <InlineSVG :src="require('@/assets/svg/desktop.svg')" />
        </div>
        <span>{{ mixWB('BIGGER_SCREEN_INFO_TEXT', [minScreenWidth]) }}</span>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import Footer from './Footer'

export default {
  name: 'SmallScreenOverlay',
  props: {
    minScreenWidth: {
      type: Number,
      required: true,
    },
  },
  components: {
    SimpleHeader,
    Footer,
  },
}
</script>

<style lang="stylus" scoped>
  .SmallScreenOverlay
    display block

  .Body
    width 100%
    min-height 100vh
    background-color #000
    background-image url('/img/bg-1.jpg')
    background-size cover
    padding-bottom 100px

  .ContentWrap
    flex-center-children-column()
    max-width 570px
    width 90%
    padding 30px 30px 70px
    background-color rgba($color_grey_lightest, 0.8)
    margin 0 auto
    span
      text-align center
      padding-bottom 15px
    .Title
      font-size 1.5rem
      font-weight bold
    .IconWrap
      flex-center-children()
      box(200px)
      margin-bottom 20px
</style>
