import { firebase, db } from '@/firebase/init'

const DB_GROUPS = 'Groups'
const DB_STATS = 'Stats'

export const groups = {
  state: {
    allGroups: [],
    currentGroup: {
      id: null,
      data: null,
    },

    // Unsubscribers
    unsubscribeAllGroups: null,
    unsubscribeCurrentGroup: null,
  },
  mutations: {
    setAllGroups: (state, { allGroups, unsubscribe }) => {
      state.allGroups = allGroups
      state.unsubscribeAllGroups = unsubscribe
    },
    setCurrentGroup: (state, { unsubscribe, currentGroupData, groupID }) => {
      state.unsubscribeCurrentGroup = unsubscribe
      state.currentGroup.id = groupID
      state.currentGroup.data = currentGroupData
    },
    resetGroups: (state) => {
      if (state.unsubscribeCurrentGroup) {
        state.unsubscribeCurrentGroup()
        state.unsubscribeCurrentGroup = null
        state.currentGroup.id = null
        state.currentGroup.data = null
      }
      if (state.unsubscribeAllGroups) {
        state.unsubscribeAllGroups()
        state.unsubscribeAllGroups = null
        state.allGroups = []
      }
    },
  },
  actions: {
    addGroup: (store, groupName) => {
      // Add to Groups
      db.collection(DB_GROUPS).add({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        memberEmails: [],
        name: groupName,
        answers: {},
        totalMembers: 0,
        totalLogins: 0,
      })

      db.collection(DB_STATS).doc('--stats--').set({
        totalGroups: firebase.firestore.FieldValue.increment(1),
        allGroupNames: firebase.firestore.FieldValue.arrayUnion(groupName),
      }, { merge: true })
    },
    getAllGroups: ({ commit, getters }) => {
      if (getters.allGroupsLoaded) {
        return
      }

      const unsubscribe = db.collection(DB_GROUPS)
        .onSnapshot((querySnapshot) => {
          const allGroups = []
          querySnapshot.forEach((doc) => {
            allGroups.push({
              ...doc.data(),
              id: doc.id,
            })
          })

          commit('setAllGroups', { allGroups, unsubscribe })
        })
    },
    getCurrentGroup: ({ commit, getters }, groupID) => {
      if (getters.currentGroupLoaded) {
        return
      }

      const waitForUser = setInterval(() => {
        if (!groupID && getters.currentUserData) {
          groupID = getters.currentUserData.groupID
        }
        if (!groupID) {
          return
        }

        clearInterval(waitForUser)

        const unsubscribe = db
          .collection(DB_GROUPS)
          .doc(groupID)
          .onSnapshot((doc) => {
            const data = doc.data()
            commit('setCurrentGroup', {
              unsubscribe,
              groupID: data ? groupID : null,
              currentGroupData: data ? doc.data() : null,
            })
          })
      }, 50)
    },
    resetGroups: ({ commit }) => {
      commit('resetGroups')
    },
  },
  getters: {
    // Loaded getters
    allGroupsLoaded: (state) => !!state.unsubscribeAllGroups,
    currentGroupLoaded: (state) => !!state.unsubscribeCurrentGroup,

    // Other getters
    allGroups: (state) => state.allGroups,
    allGroupsAsObject: (state) => state.allGroups.reduce((prev, group) => {
      prev[group.id] = group
      return prev
    }, {}),
    allGroupNamesLowerCased: (state, getters) => {
      if (!getters.allStatsLoaded) {
        return []
      }
      return getters.allStats.allGroupNames.map((x) => x.toLowerCase())
    },
    currentGroup: (state) => state.currentGroup,
    currentGroupID: (state) => state.currentGroup.id,
    currentGroupData: (state) => state.currentGroup.data,
  },
}
