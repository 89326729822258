<template>
  <div
    v-if="show"
    class="LoadingPage">
    <div class="Center">
      <Circular />
    </div>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular'

export default {
  name: 'LoadingPage',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: { Circular },
}
</script>

<style lang="stylus" scoped>
  .LoadingPage
    position fixed
    top 0
    left 0
    flex-center-children()
    box(100%)
    background-color rgba($color_grey_lightestest, 0.5)
    z-index $loading_page

  .Center
    transform translateY(-100px)
    box(100px)

</style>
