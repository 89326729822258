import { createStore } from 'vuex'
import { firebase, db } from '@/firebase/init'
import { userAnswers } from '@/store/userAnswers'
import router from '@/router'
import { mixCompareVersionsNewerOnly } from '@/globals/javascript/mixins'
import { reportError } from '@/globals/javascript/custom-error-handing'
import { database } from './database'
import { groups } from './groups'
import { users } from './users'

const DB_STATS = 'Stats'
const DB_REPORTS = 'Reports'
const DB_FEEDBACK = 'Feedback'

export default createStore({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    isAppInitialized: false,
    isPageLoading: true,
    isLoggedIn: false,
    allStats: {},
    contactInfo: {
      phone: '51 91 01 01',
      email: 'standard@milva.dk',
    },

    // Unsubscribers
    unsubscribeAllStats: null,
  },
  mutations: {
    setAppInitializedState: (state, status) => {
      state.isAppInitialized = status
    },
    setPageLoad: (state, newState) => {
      state.isPageLoading = newState
    },
    setUserLoginState: (state, isLoggedIn) => {
      state.isLoggedIn = isLoggedIn
    },
    setAllStats: (state, { allStats, unsubscribe }) => {
      state.allStats = allStats
      state.unsubscribeAllStats = unsubscribe
    },
  },
  actions: {
    setAppInitializedState: ({ commit }, status) => {
      commit('setAppInitializedState', status)
    },
    setupAdmin: ({ dispatch }) => {
      dispatch('getAllGroups')
      dispatch('getAllStats')
    },
    setPageLoad: ({ commit }, newState) => {
      commit('setPageLoad', newState)
    },
    setUserLoginState: ({ commit }, isLoggedIn) => {
      commit('setUserLoginState', isLoggedIn)
    },
    getAllStats: ({ commit, getters }) => {
      if (getters.allStatsLoaded) {
        return
      }

      const unsubscribe = db
        .collection(DB_STATS)
        .doc('--stats--')
        .onSnapshot((doc) => {
          commit('setAllStats', { allStats: doc.data(), unsubscribe })
        })
    },
    resetOnLogout: ({ dispatch }) => {
      dispatch('resetCurrentUser')
      dispatch('resetGroups')
    },
    checkForNewAppVersion: ({ getters }) => {
      const waitForLoad = setInterval(() => {
        const { packageVersion } = getters
        const { firestoreAppVersion } = getters

        if (!packageVersion || !firestoreAppVersion) {
          return
        }

        clearInterval(waitForLoad)

        const isNewVersionAvailable = mixCompareVersionsNewerOnly(
          firestoreAppVersion,
          packageVersion,
        )

        if (!isNewVersionAvailable) {
          return
        }

        setTimeout(() => {
          const locationArray = window.location.href.split('?')
          window.location.href = `${ locationArray[0] }?version=${ firestoreAppVersion }-${ new Date().getTime() }`
        }, 200)
      }, 100)
    },
    reportWindowSize() {
      try {
        db
          .collection(DB_REPORTS)
          .doc('--reports--')
          .set({
            screenSizes: firebase.firestore.FieldValue.arrayUnion({
              date: new Date().toGMTString(),
              innerWidth: window.innerWidth,
              innerHeight: window.innerHeight,
              screenWidth: window.screen && window.screen.width ? window.screen.width : null,
              screenHeight: window.screen && window.screen.height ? window.screen.height : null,
              url: window.location.href,
            }),
          }, { merge: true })
      }
      catch (err) {
        reportError({ error: err, place: 'reportWindowSize' })
      }
    },
    sendFeedback: ({ getters }, text) => {
      try {
        db
          .collection(DB_FEEDBACK)
          .add({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            text,
            userID: getters.currentUserID,
            groupID: getters.currentUserData.groupID || null,
          })

        db.collection(DB_STATS).doc('--stats--').set({
          totalFeedback: firebase.firestore.FieldValue.increment(1),
        }, { merge: true })
      }
      catch (err) {
        reportError({ error: err, place: 'feedback' })
      }
    },
  },
  getters: {
    // Loaded getters
    allStatsLoaded: (state) => !!state.unsubscribeAllStats,

    // Other getters
    packageVersion: (state) => state.packageVersion,
    firestoreAppVersion: (state, getters) => {
      if (!getters.allStatsLoaded) {
        return null
      }
      return state.allStats.latestAppVersion
    },
    contactInfo: (state) => state.contactInfo,
    isAppInitialized: (state) => state.isAppInitialized,
    isPageLoading: (state) => state.isPageLoading,
    isLoggedIn: (state) => state.isLoggedIn,
    allStats: (state) => state.allStats,
    allStatsAnswers: (state) => state.allStats.answers,

    currentQuestionSchema: (state, getters) => getters.questionSchemas.find(
      (x) => x.name === router.currentRoute.value.params.questionnaireName,
    ),
    currentQuestionSchemaSavedUserAnswers: (state, getters) => {
      if (
        !getters.currentUserAnswers
        || !getters.currentUserAnswers[getters.currentQuestionSchema.id]
      ) {
        return false
      }

      return getters.currentUserAnswers[getters.currentQuestionSchema.id]
    },
  },
  modules: {
    database,
    groups,
    users,
    userAnswers,
  },
})
