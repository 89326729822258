<template>
  <div class="Menu">
    <div class="Top">
      <span class="Title">Milva E</span>
      <span class="SubTitle">Admin</span>
    </div>
    <div class="Nav">
      <div class="Group">
        <NavItem
          :title="mixWB('DASHBOARD')"
          :path="{ name: 'AdminDashboard' }"/>
      </div>
      <div class="Group">
        <span class="GroupTitle">{{ mixWB('USERS') }}</span>
        <NavItem
          :title="mixWB('CREATE_NEW_GROUP')"
          :path="{ name: 'AdminNewGroup' }"/>
        <NavItem
          :title="mixWB('ALL_GROUPS')"
          :path="{ name: 'AdminAllGroups' }"/>
      </div>
      <div class="Group">
        <NavItem
          :title="mixWB('LOG_OUT')"
          @item-click="onLogOutClick" />
      </div>
    </div>
  </div>
</template>

<script>
import NavItem from './NavItem'

export default {
  name: 'Menu',
  methods: {
    onLogOutClick() {
      this.$router.push({ name: 'LogOut' })
    },
  },
  components: {
    NavItem,
  },

}
</script>

<style lang="stylus" scoped>
  .Menu
    position fixed
    top 0
    left 0
    width 300px
    height 100vh
    background-color $color_grey_dark

  .Top
    padding 10px
    border-bottom 1px solid $color_grey_light
    span
      display block
      color #fff
      text-align center
    .Title
      font-size 2rem

  .Nav
    .Group
      padding 10px 0px
      border-bottom 1px solid $color_grey_light
      &:last-child
        border-bottom none
    .GroupTitle
      display block
      font-size 0.75rem
      text-transform uppercase
      padding-left 20px
</style>

<style lang="stylus">
  .Menu
    span
      color $color_grey_lighter
</style>
