import { firebase, db } from '@/firebase/init'
import { forOwn, isEmpty } from 'lodash-es'
import { reportError } from '@/globals/javascript/custom-error-handing'

const DB_ANSWERS = 'Answers'
const DB_COMMENTS = 'Comments'
const DB_STATS = 'Stats'
const DB_GROUPS = 'Groups'

export const userAnswers = {
  state: {
    currentSchemaComments: null,

    // Unsubscribers
    unsubscribeCurrentSchemaComments: null,
  },
  mutations: {
    setCurrentComments: (state, { comments, unsubscribe }) => {
      state.currentSchemaComments = comments
      state.unsubscribeCurrentSchemaComments = unsubscribe
    },
    resetCurrentSchemaComments: (state) => {
      if (state.unsubscribeCurrentSchemaComments) {
        state.currentSchemaComments = null
        state.unsubscribeCurrentSchemaComments()
        state.unsubscribeCurrentSchemaComments = null
      }
    },
  },
  actions: {
    // Getters
    getCurrentSchemaComments: ({ commit, getters }) => {
      const unsubscribe = db
        .collection(DB_COMMENTS)
        .where('questionnaireID', '==', getters.currentQuestionSchema.id)
        .orderBy('lastUpdated', 'desc')
        .onSnapshot((querySnapshot) => {
          const comments = []
          querySnapshot.forEach((doc) => {
            comments.push({
              ...doc.data(),
              id: doc.id,
            })
          })

          commit('setCurrentComments', { comments, unsubscribe })
        })
    },

    // Setters
    async setUserAnswers({ dispatch, getters }, { questionnaireID, answers }) {
      // Set comments and get missing comment ID's
      const updatedAnswers = await dispatch('setCommentsFromAnswers', { questionnaireID, answers })

      try {
        // Save answers
        db
          .collection(DB_ANSWERS)
          .doc(getters.currentUserID)
          .set({
            [questionnaireID]: {
              lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
              answers: updatedAnswers,
            },
          }, { merge: true })
      }
      catch (err) {
        reportError({ error: err, place: 'setUserAnswers' })
      }

      // Update stats
      dispatch('setStatsFromAnswers', { questionnaireID, answers })
    },
    setCommentsFromAnswers: ({ getters }, { questionnaireID, answers }) => {
      let newCommentsCount = 0
      forOwn(answers, (value, key) => {
        // Check if nothing is there
        if (!value.comment.text && !value.comment.id) {
          return
        }

        // Check if comment and answer is unchanged
        if (
          getters.currentQuestionSchemaSavedUserAnswers
          && getters.currentQuestionSchemaSavedUserAnswers.answers[key]
          && getters.currentQuestionSchemaSavedUserAnswers.answers[key].answer
            === value.answer
          && getters.currentQuestionSchemaSavedUserAnswers.answers[key].comment.text
            === value.comment.text
        ) {
          return
        }

        // Check if comment needs to be deleted
        if (!value.comment.text && value.comment.id) {
          try {
            db
              .collection(DB_COMMENTS)
              .doc(value.comment.id)
              .delete()
          }
          catch (err) {
            reportError({ error: err, place: 'setCommentsFromAnswers - delete comment' })
          }
          value.comment.id = null
          value.comment.text = ''
          newCommentsCount -= 1
          return
        }

        // Check if comment has ID
        let commentID = value.comment.id
        let isNew = false
        if (!commentID) {
          const docRef = db.collection(DB_COMMENTS).doc()
          commentID = docRef.id
          isNew = true
          value.comment.id = commentID
          newCommentsCount += 1
        }

        // Save comment in DB
        try {
          const newData = {}

          if (isNew) {
            newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
          }

          db
            .collection(DB_COMMENTS)
            .doc(commentID)
            .set({
              ...newData,
              lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
              questionID: key,
              questionnaireID,
              text: value.comment.text,
              answer: value.answer,
              groupID: getters.currentUserData.groupID,
              user: {
                id: getters.currentUserID,
                firstName: getters.currentUserData.firstName,
                lastName: getters.currentUserData.lastName,
              },
            }, { merge: true })
        }
        catch (err) {
          reportError({ error: err, place: 'setCommentsFromAnswers - save comments' })
        }
      })

      // Update stats for total comments
      if (newCommentsCount !== 0) {
        try {
          db
            .collection(DB_STATS)
            .doc('--stats--')
            .set({
              totalComments: firebase.firestore.FieldValue.increment(newCommentsCount),
            }, { merge: true })
        }
        catch (err) {
          reportError({ error: err, place: 'setCommentsFromAnswers - update total comments' })
        }
      }

      return new Promise((resolve) => {
        resolve(answers)
      })
    },
    setStatsFromAnswers: ({ getters }, { questionnaireID, answers }) => {
      const increment = firebase.firestore.FieldValue.increment(1)
      const decrement = firebase.firestore.FieldValue.increment(-1)

      const stats = Object.keys(answers).reduce((prev, key) => {
        const value = answers[key]

        // Find privious answer
        let previousAnswer = null
        if (
          getters.currentQuestionSchemaSavedUserAnswers
          && getters.currentQuestionSchemaSavedUserAnswers.answers[key]
        ) {
          previousAnswer = getters.currentQuestionSchemaSavedUserAnswers.answers[key].answer
        }

        // Check for no previous answer
        if (
          previousAnswer === null
        ) {
          prev[key] = {
            [value.answer]: increment,
          }
          return prev
        }

        // Check for same previous answer
        if (
          previousAnswer === value.answer
        ) {
          return prev
        }

        prev[key] = {
          [previousAnswer]: decrement,
          [value.answer]: increment,
        }

        return prev
      }, {})

      if (isEmpty(stats)) {
        return
      }

      // Set group stats
      try {
        db
          .collection(DB_GROUPS)
          .doc(getters.currentUserData.groupID)
          .set({
            answers: {
              [questionnaireID]: {
                ...stats,
              },
            },
          }, { merge: true })
      }
      catch (err) {
        reportError({ error: err, place: 'setStatsFromAnswers - set group stats' })
      }

      // Set overall stats
      try {
        db
          .collection(DB_STATS)
          .doc('--stats--')
          .set({
            answers: {
              [questionnaireID]: {
                ...stats,
              },
            },
          }, { merge: true })
      }
      catch (err) {
        reportError({ error: err, place: 'setStatsFromAnswers - set overall stats' })
      }
    },

    // Resetters
    resetCurrentSchemaComments: ({ commit }) => {
      commit('resetCurrentSchemaComments')
    },
  },
  getters: {
    currentSchemaComments: (state) => state.currentSchemaComments,
  },
}
