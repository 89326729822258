<template>
  <div
    :class="rootClasses"
    class="Circular"/>
</template>

<script>
export default {
  name: 'Circular',
  props: {
    size: {
      type: String,
      required: false,
      default: 'small', // 'tiny' | 'small' | 'medium'
    },
  },
  computed: {
    rootClasses() {
      return {
        SizeTiny: this.size === 'tiny',
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Circular
    box(100%)
    flex-center-children()
    &.SizeTiny
      loadingStyle(26px)
    &.SizeSmall
      loadingStyle(40px)
    &.SizeMedium
      loadingStyle(60px)
</style>
