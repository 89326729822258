import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'
import { adminRoutes } from '@/router/admin'
import {
  waitForInitialized,
  checkAllIsLoaded,
  checkAllIsLoadedPromise,
} from '@/router/helper'

const routes = [
  ...adminRoutes,
  {
    path: '/',
    name: 'Home',
    meta: {
      fullBackground: true,
      isGuestPage: true,
    },
    component: () => import('@/views/Home'),
  },
  {
    path: '/start-link/:groupID',
    name: 'StartLink',
    meta: {
      fullBackground: true,
      isGuestPage: true,
      checklist: [
        'currentGroupLoaded',
      ],
    },
    component: () => import('@/views/StartLink'),
  },
  {
    path: '/auto-login',
    name: 'AutoLogin',
    meta: {
      fullBackground: true,
    },
    component: () => import('@/views/AutoLogin'),
  },
  {
    path: '/start',
    name: 'Start',
    meta: {
      fullBackground: true,
      isAuthPage: true,
      checklist: [
        'currentUserLoaded',
        'currentUserAnswersLoaded',
      ],
    },
    component: () => import('@/views/Start'),
  },
  {
    path: '/velkommen',
    name: 'Welcome',
    meta: {
      isAuthPage: true,
      checklist: [
        'currentUserLoaded',
        'currentUserAnswersLoaded',
        'allGroupsLoaded',
        'currentGroupLoaded',
      ],
    },
    component: () => import('@/views/Welcome'),
  },
  {
    path: '/skema/:questionnaireName',
    name: 'Questionnaire',
    meta: {
      isAuthPage: true,
      checklist: [
        'currentUserLoaded',
        'currentUserAnswersLoaded',
        'allGroupsLoaded',
        'currentGroupLoaded',
      ],
    },
    component: () => import('@/views/Questionnaire'),
  },
  {
    path: '/tak-for-nu',
    name: 'Done',
    meta: {
      isAuthPage: true,
      checklist: [
        'currentUserLoaded',
        'currentUserAnswersLoaded',
        'allGroupsLoaded',
        'currentGroupLoaded',
      ],
    },
    component: () => import('@/views/Done'),
  },
  {
    path: '/log-ud',
    name: 'LogOut',
    meta: {
      fullBackground: true,
      isAuthPage: true,
    },
    component: () => import('@/views/LogOut'),
  },
  // Catch rest
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {},
    component: () => import('@/views/NotFound'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// Checks and sets
router.beforeEach(() => {
  // Check for newer version
  store.dispatch('checkForNewAppVersion')

  // Sentry - Set page name
  // Sentry.setTag('page-name', to.name || null)
})

// Guest pages
router.beforeEach(async (to, from, next) => {
  if (!to.meta.isGuestPage) {
    next()
    return
  }

  await waitForInitialized(store)

  const { isLoggedIn } = store.getters

  if (isLoggedIn) {
    await checkAllIsLoadedPromise({ store, checklist: ['currentUserLoaded'] })

    const { currentUserData } = store.getters

    if (
      to.meta.isAdminLoginPage
      && currentUserData.isAdmin
    ) {
      next({ name: 'AdminDashboard' })
      return
    }
    next({ name: 'Start' })
    return
  }

  next()
})

// Admin pages
router.beforeEach(async (to, from, next) => {
  if (!to.meta.isAdminPage) {
    next()
    return
  }

  await waitForInitialized(store)

  const { isLoggedIn } = store.getters

  if (!isLoggedIn) {
    next({ name: 'AdminLogin' })
    return
  }

  await checkAllIsLoadedPromise({ store, checklist: ['currentUserLoaded'] })

  const { currentUserData } = store.getters

  if (!currentUserData || !currentUserData.isAdmin) {
    next({ name: 'AdminLogin' })
    return
  }

  next()
})

// Check if required data is loaded
router.beforeEach(async (to, from, next) => {
  await waitForInitialized(store)

  const pageChecklist = to.meta.checklist || []

  // Required by all
  const checklist = ['allStatsLoaded'].concat(pageChecklist)

  // Required by the specific page
  if (checklist && checklist.length) {
    if (checklist.includes('allGroupsLoaded')) {
      store.dispatch('getAllGroups')
    }
    if (checklist.includes('currentGroupLoaded')) {
      store.dispatch('getCurrentGroup', to.params.groupID)
    }
    if (checklist.includes('allStatsLoaded')) {
      store.dispatch('getAllStats')
    }
    if (checklist.includes('currentUserAnswersLoaded')) {
      store.dispatch('getCurrentUserAnswers')
    }
  }

  const answer = checkAllIsLoaded({ store, checklist: pageChecklist })
  if (answer) {
    store.dispatch('setPageLoad', false)
    next()
    return
  }

  // Wait until all requirements are loaded
  store.dispatch('setPageLoad', true)
  await checkAllIsLoadedPromise({ store, checklist: pageChecklist })

  store.dispatch('setPageLoad', false)

  next()
})

export default router

