<template>
  <div class="SimpleHeader">
    <div class="LogoWrap">
      <img
        src="@/assets/svg/logos/milva-logo-white.svg" />
      <span><b>E</b>ncyclopidia</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleHeader',

}
</script>

<style lang="stylus" scoped>
  .SimpleHeader
    display block
    padding 40px 0px
    margin-bottom 40px
    +below($desktop)
      padding 25px 0px
      margin-bottom 10px
    +below($tablet)
      padding 20px 0px
      margin-bottom 10px

  .LogoWrap
    display flex
    justify-content center
    align-items center
    img
      display block
      width 187px
      height 50px
      margin-right 15px
    span
      color #fff
      font-size 2.25rem
      b
        font-size 4.25rem
        color #fff
    +below($desktop)
      img
        width 112px
        height 30px
      span
        font-size 1.25rem
        b
          font-size 2.25rem
</style>
