/* eslint-disable no-undef */
import { createApp } from 'vue'
import InlineSvg from 'vue-inline-svg'
import App from './App'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './globals/stylus/reset.styl'
import { mixins } from './globals/javascript/mixins'
import { reportError } from './globals/javascript/custom-error-handing'

// Custom error reporting
window.addEventListener('error', (error) => {
  reportError({ error })
  return false
})

const app = createApp(App).use(store).use(router)

app.mixin(mixins)
app.component('InlineSVG', InlineSvg)

app.mount('#app')
