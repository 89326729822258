export const waitForInitialized = ((store) => new Promise((resolve) => {
  const waitForLoad = setInterval(() => {
    const { isAppInitialized } = store.getters
    if (!isAppInitialized) return

    clearInterval(waitForLoad)
    resolve()
  }, 50)
}))

export const checkAllIsLoaded = ({
  store,
  checklist,
}) => checklist.reduce((prev, item) => {
  if (!prev) {
    return prev
  }

  if (!store.getters[item]) {
    prev = false
  }
  return prev
}, true)

export const checkAllIsLoadedPromise = ({
  store,
  checklist,
}) => new Promise((resolve) => {
  const waitForAllLoaded = setInterval(() => {
    const allLoaded = checkAllIsLoaded({ store, checklist })
    if (!allLoaded) {
      return
    }

    clearInterval(waitForAllLoaded)
    resolve()
  }, 100)
})
