<template>
  <div>
    <router-link
      v-if="path"
      :to="path"
      class="NavItem">
      <span class="Title">{{ title }}</span>
    </router-link>
    <div
      v-if="!path"
      class="NavItem"
      @click="onClick">
      <span class="Title">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavItem',
  emits: [
    'item-click',
  ],
  props: {
    title: {
      type: String,
      required: true,
    },
    path: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },
  methods: {
    onClick() {
      this.$emit('item-click')
    },
  },
}
</script>

<style lang="stylus" scoped>
  .NavItem
    display block
    padding 10px 20px
    cursor pointer

  .Title
    display block
    font-size 1.25rem
</style>
