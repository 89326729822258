<template>
  <div class="Footer">
    <div class="ContentWrap">
      <div class="Box Contact">
        <span class="Title">{{ mixWB('CONTACT') }}</span>
        <div class="Row">
          <span>{{ mixWB('EMAIL') }}:</span>
          <a :href="'mailto:' + contactInfo.email">{{ contactInfo.email }}</a>
        </div>
        <div class="Row">
          <span>{{ mixWB('PHONE') }}:</span>
          <a :href="'tel:' + contactInfo.phone">{{ contactInfo.phone }}</a>
        </div>
      </div>
      <div class="Box">
        <span class="Title">{{ mixWB('MORE_INFORMATION') }}</span>
        <a href="http://www.milva.dk/">milva.dk</a>
        <a href="http://www.holdensen.nu/">holdensen.nu</a>
      </div>
    </div>

    <div class="SplitLine"/>

    <span class="CopyLine">© 2021 Omtanken ApS</span>

    <!-- Version -->
    <span class="PackageVersion">{{ getPackageVersionText() }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapGetters([
      'packageVersion',
      'contactInfo',
    ]),
  },
  methods: {
    getPackageVersionText() {
      return `${ this.mixWB('VERSION_X', [this.packageVersion]) } @ ${ process.env.NODE_ENV }`
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Footer
    position relative
    background-color $color_grey_lightest
    padding-top 45px
    padding-bottom 20px

  .ContentWrap
    display flex
    justify-content space-between
    max-width 700px
    width 100%
    margin 0 auto 20px
    span,
    a
      display block
      color $color_grey
      padding-bottom 5px
      font-size 0.875rem
    .Title
      font-weight bold
      padding-bottom 10px
    a
      &:hover
        text-decoration underline
    .Contact
     .Row
      display flex
      align-items center
      span
        margin-right 6px
    +below($tablet)
      flex-center-children-column()
      span,
      a
        text-align center
      .Box
        margin-bottom 20px
        &:last-child
          margin-bottom 0

  .SplitLine
    border-top 1px solid $color_grey_light
    max-width 800px
    width 80%
    margin 0 auto 25px

  .CopyLine
    display block
    font-size 0.665rem
    text-align center
    +below($tablet)
      margin-bottom 30px

  .PackageVersion
    position absolute
    left 15px
    bottom 15px
    font-size 0.665rem
    line-height 1
    color $color_grey_light
    +below($tablet)
      text-align center
      left 0
      width 100%
</style>
